import axios from "axios";
import "./App.css";
import { useEffect, useState } from "react";
import Card from "./components/card/Card";

function App() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getHotels = async () => {
    setIsLoading(true);
    await axios
      .get("https://hotels.scserver.org/hotels")
      .then((res) => {
        console.log("res--->", res?.data?.hotels);
        setData(res?.data?.hotels);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err--->", err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getHotels();
  }, []);

  return (
    <div className="App">
      {isLoading ? (
        <>
          <iframe
            src="https://giphy.com/embed/3oEjI6SIIHBdRxXI40"
            width="100%"
            height="100%"
            frameBorder="0"
            className="giphy-embed"
            allowFullScreen
          ></iframe>
        </>
      ) : (
        <>
          {data.length === 0 ? (
            <h1 style={{ color: "red" }}>
              Due to Maintenance! Server down in "Hotelbeds"
            </h1>
          ) : (
            <>
            <div style={{
              alignItems:'center'
            }}>
              {data.slice(0, 30).map((item) => {
                return (
                  <Card
                  name={item.name.content}
                  address={item.address.content}
                  city={item.city.content}
                  email={item.email}
                  bookingNumber={item.phones[0].phoneNumber}
                  web={item.web}
                  imageUrl={item.images[0].path}
                  />
                 );
              })}
            </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default App;
