import "./Card.css";

const Card = ({ name, address, city, email, bookingNumber, web }) => {
  console.log('web',web?.replace(/^a-zA-Z0-9 ]/g, ''))
  return (
    <>
      <div
        style={{
          display: "inline-flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
          alignItems: "center",
          marginBottom: "2%",
          marginLeft: "1%",
          marginRight: "2%",
        }}
      >
        <div className="cards_item">
          <div className="card">
            <div className="card_image">
            </div>
            <div className="card_content">
              <h2 className="card_title">{name.slice(0,6)}</h2>
              <p className="card_text">
             {city.slice(0,8)}
              <hr></hr>
              </p>
              <h4 sclassName="card_title">{"Contact Us By"}</h4>
              <hr></hr>
              <span style={{marginLeft:'0%',color:'white',fontSize:'12px'}}>{bookingNumber}</span>
              <p>
              <a href={web} target="_blank">{"check Web"}</a>
              </p>
              <button className="btn">Find More</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
